<template>
  <div class="event-card">
    <CardUI class="clickable" @click.native="$router.push({name: 'events.item.info', params: {id: event.id}})">
      <template v-slot:footer>
        <div style="display: flex; width: 100%; align-items: center">
          <v-avatar v-if="event.user" :size="24" class="mr-3">
            <v-img :lazy-src="event.user.avatar_16" :src="event.user.avatar"></v-img>
          </v-avatar>
          <div style="flex: 2; margin-right: 5px">
            <div
                style="font-size: 14px; max-height: 45px; overflow-y: hidden; max-lines: 2;  display: -webkit-box;   -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-overflow: ellipsis; ">
              {{ event.user.name }}
            </div>
          </div>
          <div class="d-inline-block ml-1" style="font-size: 12px; color: #696969">
            {{ $moment.utc(event.start_at).fromNow() }}
          </div>
        </div>
      </template>
      <template v-slot:body>
        <ImgUI v-if="event.header_url" :src="event.header_url"/>
        <h2 class="mt-2">{{event.name}}</h2>
        <div>
          <span style="font-weight: 300">Регистрация</span> <br>{{$moment(event.check_start_at).format('DD.MM.YY HH:mm')}} - {{$moment(event.check_end_at).format('DD.MM HH:mm')}}
        </div>
        <div class="my-2">
          <span style="font-weight: 300">Даты проведения</span> <br>{{$moment(event.start_at).format('DD.MM.YY HH:mm')}} - {{$moment(event.end_at).format('DD.MM HH:mm')}}
        </div>
        <div class="mt-1"
             style="height: 4.5em; overflow-y: hidden; max-lines: 3;  display: -webkit-box;   -webkit-line-clamp: 3; -webkit-box-orient: vertical; text-overflow: ellipsis; font-size: 14px;">
          {{ event.description }}
        </div>
<!--        <div class="mt-2">-->
<!--          <div class="tags">-->
<!--            <div v-if="event.teams_allowed" class="tag">#команда</div>-->
<!--            <div v-if="event.is_online" class="tag">#online</div>-->
<!--          </div>-->
<!--        </div>-->
      </template>
    </CardUI>
  </div>
</template>

<script>
import CardUI from "@/components/UI/CardUI.vue";
import ImgUI from "@/components/UI/ImgUI.vue";

export default {
  name: "EventCardUI",
  components: {CardUI, ImgUI},
  props: {
    event: {}
  }
}
</script>

<style scoped>
.clickable {
  transition: all 0.5s;
  box-sizing: inherit;
  border: 1px solid transparent;
}

.clickable:hover {
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #eaeaea;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin-right: 10px;
  background: #eaeaea;
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
}
</style>
