<template>
  <div>
    <div
        style="height: 200px; border-radius: 10px; width: 100%; display: flex; justify-content: center; align-items: center; overflow: hidden">
      <img @load="onLoad" :src="src" alt="" style="max-width: 100%;">
    </div>
  </div>
</template>

<script>

export default {
  name: "ImgUI",
  props: {
    src: String,
  },
  methods: {
    onLoad() {
    },

  }
}
</script>

<style scoped>

</style>
