<template>
  <v-container fluid>
    <v-row>

      <v-col cols="12" lg="7">
        <CardUI :loading="loading">
          <template v-slot:header>
            <v-card-title>Создание мероприятия</v-card-title>
          </template>
          <template v-slot:body>
            <v-card-text>
              <v-alert v-for="(e,i) in errors" :key="i" type="error">{{ e[0] }}</v-alert>
              <InputUI label="Название мероприятия" v-model="event.name" :errors="errors.name" outlined :placeholder="randomName()"/>
              <h4 class="my-4">Описание мероприятия</h4>
              <TextAreaUI v-model="event.description" :errors="errors.description" placeholder="Описание"/>

              <h4 class="my-4">Как вы планируете провести мероприятие?</h4>
              <SelectorUI v-model="event.is_online" :default-value="true" :items="[
                          {name: 'Онлайн', value: true},
                          {name: 'Оффлайн', value: false},
                      ]"/>



              <InputUI v-if="event.is_online" class="mt-4" v-model="event.meet_url" :errors="errors.phone"
                       placeholder="Ссылка для подключения"/>


              <v-switch v-if="!event.is_online" v-model="inner"
                        label="Мероприятие проводится внутри университета"/>


              <InputUI v-if="!inner && !event.is_online" v-model="event.address" :errors="errors.address"
                       placeholder="Адрес"/>
              <SelectUI v-if="inner" v-model="event.place_id" :clearable="true"
                        :errors="errors.place_id"
                        :items="$store.state.timetables.places"
                        item-text="name"
                        item-value="id"
                        no-data-text="Такой аудитории у нас нет"
                        placeholder="Аудитория проведения"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple
                               @click="() => {createPlaceModalShow = true}"
                  >
                    <v-list-item-action>
                      <v-icon color="green">mdi-plus</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Создать аудиторию</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

              </SelectUI>
              <h4 class="my-4">Период регистрации</h4>
              <DatePickerUIDecorator>
                <DatePicker format="DD.MM" :disabled-date="notBeforeToday"
                            :range=true
                            value-type="YYYY-MM-DD"
                            :show-second="false"
                            :value="[event.check_start_at, event.check_end_at]" input-class="date-input"
                            placeholder="Период регистрации"
                            type="date"
                            @input="event.check_start_at =  $event[0]; event.check_end_at =  $event[1];"></DatePicker>
              </DatePickerUIDecorator>

              <h4 class="my-4">Период проведения</h4>
              <DatePickerUIDecorator>
                <DatePicker value-type="YYYY-MM-DD HH:mm" format="DD.MM HH:mm" :disabled-date="notBeforeTodayAndCheck"
                            :disabled-time="notBeforeTodayAndCheck" :range=true
                            :show-second="false" :value="[event.start_at, event.end_at]" placeholder="Период проведения"
                            type="datetime" input-class="date-input"
                            @input="event.start_at =  $event[0]; event.end_at =  $event[1];"></DatePicker>
              </DatePickerUIDecorator>
              <SelectUI class="mt-4" v-model="event.event_type_id" :errors="errors.event_type_id"
                        :items="$store.state.events.event_types" item-text="name" item-value="id"
                        placeholder="Тип мероприятия"/>

              <InputUI class="mt-4" v-model="event.phone" v-mask="'+7##########'" :errors="errors.phone"
                       placeholder="Телефон для справки"/>
              <v-switch v-model="event.teams_allowed" :error-messages="errors.teams_allowed"
                        label="Командное участие"/>
              <h4>Выберите постер</h4>
              <FileUpload @input="event.header_url = $event[0].link" :only-types="/jpg|png|gif|jpeg/" :multiple="false"
                          v-model="header_files" class="mt-3 mb-3"/>
            </v-card-text>
            <v-card-actions>
              <ButtonUI :loading="loading" color="blue darken-2" outlined @click="save">Добавить мероприятие</ButtonUI>
            </v-card-actions>
          </template>
        </CardUI>
      </v-col>
      <v-col lg="5" cols="12">
        <h4>Предварительный просмотр</h4>
        <EventCardUI :event="event"/>
      </v-col>
    </v-row>

    <v-dialog v-model="createPlaceModalShow" max-width="500">
      <CreatePlaceModal
          @close="() => {this.createPlaceModalShow = false;}" @create="place = $event"/>
    </v-dialog>
  </v-container>
</template>

<script>

import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import {mask} from 'vue-the-mask'
import CardUI from "@/components/UI/CardUI.vue";
import CreatePlaceModal from "@/components/Timetables/DisplinesCreator/CreatePlaceModal";
import InputUI from "@/components/UI/InputUI.vue";
import TextAreaUI from "@/components/UI/TextAreaUI.vue";
import SelectorUI from "@/components/UI/SelectorUI.vue";
import SelectUI from "@/components/UI/SelectUI.vue";
import DatePicker from 'vue2-datepicker';
import DatePickerUIDecorator from "@/components/UI/DatePickerUIDecorator.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import FileUpload from "@/components/Common/FileUpload.vue";
import EventCardUI from "@/components/Events/EventCardUI.vue";

export default {
  directives: {
    mask,
  },
  components: {
    ButtonUI,
    FileUpload, EventCardUI,
    SelectUI,
    CreatePlaceModal, CardUI, InputUI, TextAreaUI, SelectorUI, DatePicker, DatePickerUIDecorator
  },
  name: "EventsCreateComponent",
  data() {
    return {
      loading: true,
      inner: false,
      createPlaceModalShow: false,
      header_bg: null,
      header_files: [],
      event: {
        place_id: null,
        name: null,
        address: null,
        meet_url: null,
        phone: this.$store.getters.isAuth ? this.$store.state.user.currentUser.phone : null,
        description: this.$store.getters.isAuth ? 'Мероприятие для всех желающих. Организатор - ' + this.$store.state.user.currentUser.name : null,
        check_start_at: null,
        check_end_at: null,
        teams_allowed: false,
        start_at: null,
        end_at: null,
        event_type_id: null,
        user_role_id: null,
        is_online: false
      },
      errors: {},
      event_roles: []
    }
  },
  computed: {
    user_roles() {
      return this.$store.state.events.user_roles
    }
  },
  methods: {

    randomName() {
      const items = ['День вишневого мороженного', 'Праздник тринадцатой кошки']
      return items[items.length * Math.random() | 0];
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    notBeforeTodayAndCheck(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0)) || date < new Date(this.event.check_end_at);
    },
    addRole() {
      this.event_roles.push({
        user_role_id: null,
        points_max: 1,
        hidden: false,
        admin: false,
        can_set_points: false,
      })
    },
    removeRole(index) {
      this.event_roles.splice(index, 1)
    },

    save() {
      this.loading = true;
      let fd = new FormData();
      Object.keys(this.event).forEach((key) => {
        if (key.indexOf('at') !== -1) {
          fd.set(key, this.$moment(this.event[key]).utc().format("YYYY-MM-DD HH:mm"))
        } else {
          if (this.event[key] === true) this.event[key] = 1;
          if (this.event[key] === false) this.event[key] = 0;
          fd.set(key, this.event[key])
        }
      });
      fd.append('header_bg', this.header_bg)
      fd.set('roles', JSON.stringify(this.event_roles));
      this.$store.dispatch('storeEvent', fd).then(() => {
        this.$router.push('/events')
        this.loading = false;
      }).catch((e) => {
        this.errors = e.response.data.errors
        this.loading = false;
      })
    }
  },
  mounted() {
    if (!this.$store.state.user.currentUser.id) return this.$router.push('/auth')
    Promise.all([
      this.$store.dispatch('getEventTypes'),
      this.$store.dispatch('getPlaces'),
      this.$store.dispatch('getUserRoles')
    ]).then(() => {
      this.loading = false;
    })

    this.$emit('changeTitle', 'Создание мероприятия')
  }
}
</script>

<style>

</style>
